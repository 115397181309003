@import "curtindesign/variables/palette";

// ------------------------------------------------------------------
//   Well Defined Colours
// ------------------------------------------------------------------

$red:               #d11528;
$green:             #00C742;


// ------------------------------------------------------------------
//   Social Media Brand Colours
// ------------------------------------------------------------------

$social-facebook:   $white;
$social-twitter:    $white;
$social-googleplus: $white;
$social-youtube:    $white;
$social-linkedin:   $white;
$social-instagram:  $white;
$social-rss:        $white;
$social-pinterest:  $white;
$social-weibo:      $white;
$social-tumblr:     $white;
$social-email:      $white;
