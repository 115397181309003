@at-root{
    @font-face {
        font-family: "icon-elsie";
        src: url('../icons/font/icon-elsie.eot?t=1536738820946');
        src: url('../icons/font/icon-elsie.eot?#iefix&t=1536738820946') format('eot'),
            url('../icons/font/icon-elsie.woff?t=1536738820946') format('woff'),
            url('../icons/font/icon-elsie.ttf?t=1536738820946') format('truetype'),
            url('../icons/font/icon-elsie.svg#icon-elsie?t=1536738820946') format('svg');
    }
}

$elsie-icon-set: (
    set-font: "icon-elsie",

    agenda-alert: "\E001",

    agenda-clock: "\E002",

    agenda-info: "\E003",

    agenda-user: "\E004",

    announcements: "\E005",

    arrow-down: "\E006",

    arrow-left: "\E007",

    arrow-right: "\E008",

    arrow-tail-down: "\E009",

    arrow-tail-left: "\E00A",

    arrow-tail-right: "\E00B",

    arrow-tail-up: "\E00C",

    arrow-up: "\E00D",

    arrow-wide-down: "\E00E",

    arrow-wide-left: "\E00F",

    arrow-wide-right: "\E010",

    arrow-wide-up: "\E011",

    assessments: "\E012",

    certificate: "\E013",

    close: "\E014",

    contact-search: "\E015",

    contacts: "\E016",

    correct: "\E017",

    delete: "\E018",

    download: "\E019",

    edit: "\E01A",

    email: "\E01B",

    envelope: "\E01C",

    error-filled: "\E01D",

    error: "\E01E",

    exams: "\E01F",

    feedback: "\E020",

    grid: "\E021",

    info: "\E022",

    key: "\E023",

    library: "\E024",

    lock: "\E025",

    logout: "\E026",

    map-marker: "\E027",

    map-pin: "\E028",

    map: "\E029",

    menu: "\E02A",

    minus: "\E02B",

    news: "\E02C",

    notes: "\E02D",

    password: "\E02E",

    pause: "\E02F",

    phone: "\E030",

    planner: "\E031",

    play-outline: "\E032",

    play: "\E033",

    plus: "\E034",

    portfolio: "\E035",

    practice: "\E036",

    progress: "\E037",

    search: "\E038",

    settings: "\E039",

    status-conditional: "\E03A",

    status-good: "\E03B",

    status-terminated: "\E03C",

    templates: "\E03D",

    tick: "\E03E",

    time: "\E03F",

    ui-kits: "\E040",

    units: "\E041",

    upload: "\E042",

    username: "\E043",

    warning: "\E044",

    workbook: "\E045",
    
);