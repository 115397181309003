@import "variables/animation";
@import "variables/colour";
@import "curtindesign/elements/tooltip";

.tooltip {
    color: $white;
    border: none;
    background-color: transparentize($black, .6);
    width: 1rem;
    height: 1rem;
    font-size: 0.75rem;
    padding: 0;
    margin-left: 0.25rem;

    &:hover,
    &:focus,
    &.open {
        background-color: transparentize($black, .4);
        border: none;
    }

    span,
    .tooltip__content {
        opacity: 0;
        left: 0;
        margin-top: 0.5rem;
        background-color: transparentize($black85, .2);
        color: $primary-colour__text;
        border-radius: 8px;
        border: none;
    }
}
