@import "variables/colour";
@import "variables/font";
@import "functions/colour";
@import "curtindesign/modifiers/table-responsive";

// ------------------------------------------------------------------
//   Tables
// ------------------------------------------------------------------
.table-responsive {
    table {
        thead {
            th {
                border-bottom: 1px solid $black20;
                font-family: $header-font-family;
            }
        }        
        >tbody,
        >tfoot {
            >tr {
                &:nth-child(odd) {
                    background-color: tint($black, 93);
                }
            }
        }
    }
}
