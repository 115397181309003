@import "curtindesign/components/link-list";

.link-list{
    &--horizontal{
        li{
            a{
                font-weight: bold;
            }
            
            &:first-child a {
                // first list item
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
            }            
            &:last-child a {
                // last list item
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }
    }
}