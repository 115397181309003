@import "variables/palette";

/*Theme*/
$body-colour: #eeeeee;
$text-colour: #868686;

$primary-colour: #1c8e8c;
$secondary-colour: #1ab5b3;

$primary-colour__text: $white;
$secondary-colour__text: $white;

$success: #1c8e8c;
$success-dark: #166b6a;
$info: #19B3FF;
$info-dark: #0C8DCC;
$warning: #FFBF00;
$warning-dark: #CC9800;
$danger: #D11528;
$danger-dark: #9F1924;

/*Normal text*/
$normal-text: #595959;

/*Header colours*/
$global-bar-background-color: $grey5;
$global-bar_inner-background-color--footer: $grey5;

/*Menu*/
$item-highlight: #efefef;

$header-colour: $primary-colour__text;

$link-list-colour: #575757;
$list-link-colour-hover: $primary-colour__text;
$list-link-colour-visited: #575757;
$list-link-colour-focus--high-contrast: $primary-colour__text;
$list-link-colour-hover--high-contrast: $primary-colour__text;

$list-link-background-colour-active: #575757;
$list-link-background-colour-hover: #575757;
$list-link-background-colour-focus: #575757;

$sub-nav-is-active-background: $grey30;

@import "curtindesign/variables/colour";