@import "variables/animation";
@import "variables/colour";
@import "curtindesign/components/tile";

.tile {
    .tile__content {
        .tile__title {
            a {
                letter-spacing: 2px;
            }
            
            a:focus {
                animation: flash $transition-speed ease-in-out 0s alternate 2 none;
            }
        }

        // Remove hover effect
        &:hover {
            background: $primary-colour;

            .tile__media {
                & > span {
                    color: watermark($primary-colour);
                }
            }
        }
    }

    &--icon {
        & .tile__media{
            opacity: 0.5;
        }
    }
}

@keyframes flash{
    0% {
        background-color: transparent;
    }
    100% {
        background-color: transparentize(tint($primary-colour, 40%), 0.75);
    }
}
