@import "variables/layout";
@import "mixins/layout";
@import "mixins/lists";

$tile-spacing: 1%;
@mixin block-grid-item($per-row, $spacing: $tile-spacing, $small-per-row: ceil($per-row/2)){
    float: left;
    width: block-grid-item-width($per-row, $spacing);
    margin: 0 $spacing $spacing 0;

    &:nth-child(#{$per-row}n){
        margin-right: 0;
    }

    @include mq($max: tablet-small){
        width: block-grid-item-width($small-per-row, $spacing);

        &:nth-child(#{$per-row}n){
            margin-right: $spacing;
        }

        &:nth-child(#{$small-per-row}n){
            margin-right: 0;
        }            
    }
}

.block-grid{
    @include clearfix;
}

@for $i from 1 through $max-block-grid-items {
    .main-content .block-grid-#{$i},
    .block-grid-#{$i}{
        @extend .block-grid;

        .block-grid-item,
        > li{
            @include block-grid-item($i);
        }
    }

    ul.block-grid-#{$i}{
        @include no-bullets;
    }

    @for $col from 1 through $block-grid-density-increase-threshold {
        .col-#{$col}{
            .block-grid-#{$i}{
                .block-grid-item,
                > li{
                    @include mq($max: tablet-small){
                        &:nth-child(#{ceil($i/2)}n){
                            margin-right: $tile-spacing;
                        }                        
                    }
                    
                    @include mq($max: tablet-land){
                        &:nth-child(#{$i}n){
                            margin-right: $tile-spacing;
                        } 

                        @include block-grid-item($i * 2);
                    }                        
                }
            }
        }
    }
}