@import "variables/animation";
@import "variables/font";
@import "curtindesign/templates/action-input";

%elsie-action-input{
    transition: all $transition-speed $transition-style;
    display: inline-block;
    outline: none;
    vertical-align: middle;
    line-height: normal;
    cursor: pointer;
    box-sizing: border-box;
    font-family: $header-font-family;
    font-size: $button-font-size;
    border-radius: $button-radius;
    text-transform: none;
    width: 100%; // Full width for mobile

    &:disabled {
        // Disabled status
        opacity: 0.5;
        cursor: not-allowed;
    }
    &.full-width {
        width: 100%;
    }    
}