@import "variables/button";
@import "functions/units";
@import "mixins/border";
@import "mixins/layout";
@import "templates/action-input";
@import "curtindesign/templates/button";

%elsie-button {
    @extend %elsie-action-input;
    margin: $space-xs 0 0;
    text-align: center;
    padding: $space-s;

    @include mq(tablet-small) {
        // Normal width on small tablet and above
        width: auto;
        margin-top: 0;
    }
    // For split button - associated information
    & .button__meta {
        display: inline;
        @include border-shaded(left);
        padding-left: $space-xs;
        margin-left: $space-xs;
    }    
}
