@import "variables/colour";
@import "variables/font";

$button-font-family:    $header-font-family;
$button-radius:         2px;
$button-template:       '%elsie-button';

// ------------------------------------------------------------------
//   Default button styling
// ------------------------------------------------------------------
$button-colour: $white;
$button-background-colour: $primary-colour;
$button-background-image: none;
$button-border-colour: $primary-colour;
$button-box-shadow: 0 0;
$button-text-shadow: none;

$button-colour-hover: $button-colour;
$button-background-colour-hover: $secondary-colour;
$button-background-image-hover: none;
$button-border-colour-hover: $secondary-colour;
$button-box-shadow-hover: 0 0;

$button-colour-active: $button-colour;
$button-background-colour-active: $button-background-colour;
$button-background-image-active: none;
$button-border-colour-active: $button-border-colour;
$button-box-shadow-active: 1px 1px 1px 1px rgba(0, 1, 1, 0.33) inset;

// ------------------------------------------------------------------
//   Primary button styling
// ------------------------------------------------------------------
$button-colour--primary: $button-colour;
$button-background-colour--primary: $button-background-colour;
$button-background-image--primary: $button-background-image;
$button-border-colour--primary: $button-border-colour;
$button-box-shadow--primary: $button-box-shadow;
$button-text-shadow--primary: $button-text-shadow;

$button-colour-hover--primary: $button-colour-hover;
$button-background-colour-hover--primary: $button-background-colour-hover;
$button-background-image-hover--primary: $button-background-image-hover;
$button-border-colour-hover--primary: $button-border-colour-hover;
$button-box-shadow-hover--primary: $button-box-shadow-hover;

$button-colour-active--primary: $button-colour-active;
$button-background-colour-active--primary: $button-background-colour-active;
$button-background-image-active--primary: $button-background-image-active;
$button-border-colour-active--primary: $button-border-colour-active;
$button-box-shadow-active--primary: $button-box-shadow-active;

// ------------------------------------------------------------------
//   Secondary button styling
// ------------------------------------------------------------------
$button-colour--secondary: $primary-colour;
$button-background-colour--secondary: transparent;
$button-background-image--secondary: none;
$button-border-colour--secondary: $button-border-colour;
$button-box-shadow--secondary: 0 0;
$button-text-shadow--secondary: none;

$button-colour-hover--secondary: $button-colour;
$button-background-colour-hover--secondary: $primary-colour;
$button-background-image-hover--secondary: none;
$button-border-colour-hover--secondary: $primary-colour;
$button-box-shadow-hover--secondary: 0 0;

$button-colour-active--secondary: $button-colour;
$button-background-colour-active--secondary: $secondary-colour;
$button-background-image-active--secondary: none;
$button-border-colour-active--secondary: $button-border-colour--secondary;
$button-box-shadow-active--secondary: $button-box-shadow-active;

// ------------------------------------------------------------------
//   Dark button styling
// ------------------------------------------------------------------
$button-colour--dark: $button-colour;
$button-background-colour--dark: $black80;
$button-background-image--dark: none;
$button-border-colour--dark: $black80;
$button-box-shadow--dark: 0 0;
$button-text-shadow--dark: none;

$button-colour-hover--dark: $button-colour;
$button-background-colour-hover--dark: $black70;
$button-background-image-hover--dark: none;
$button-border-colour-hover--dark: $black70;
$button-box-shadow-hover--dark: 0 0;

$button-colour-active--dark: $button-colour;
$button-background-colour-active--dark: $button-background-colour--dark;
$button-background-image-active--dark: none;
$button-border-colour-active--dark: $button-border-colour--dark;
$button-box-shadow-active--dark: $button-box-shadow-active;

// ------------------------------------------------------------------
//   Primary submit button styling
// ------------------------------------------------------------------
$button-colour--primary-submit: $button-colour--primary;
$button-background-colour--primary-submit: $button-background-colour--primary;
$button-background-image--primary-submit: $button-background-image--primary;
$button-border-colour--primary-submit: $button-border-colour--primary;
$button-box-shadow--primary-submit: $button-box-shadow--primary;
$button-text-shadow--primary-submit: $button-text-shadow--primary;

$button-colour-hover--primary-submit: $button-colour-hover--primary;
$button-background-colour-hover--primary-submit: $button-background-colour-hover--primary;
$button-background-image-hover--primary-submit: $button-background-image-hover--primary;
$button-border-colour-hover--primary-submit: $button-border-colour-hover--primary;
$button-box-shadow-hover--primary-submit: $button-box-shadow-hover--primary;

$button-colour-active--primary-submit: $button-colour-active--primary;
$button-background-colour-active--primary-submit: $button-background-colour-active--primary;
$button-background-image-active--primary-submit: $button-background-image-active--primary;
$button-border-colour-active--primary-submit: $button-border-colour-active--primary;
$button-box-shadow-active--primary-submit: $button-box-shadow-active--primary;

// ------------------------------------------------------------------
//   Secondary submit button styling
// ------------------------------------------------------------------
$button-colour--secondary-submit: $button-colour--secondary;
$button-background-colour--secondary-submit: $button-background-colour--secondary;
$button-background-image--secondary-submit: $button-background-image--secondary;
$button-border-colour--secondary-submit: $button-border-colour--secondary;
$button-box-shadow--secondary-submit: $button-box-shadow--secondary;
$button-text-shadow--secondary-submit: $button-text-shadow--secondary;

$button-colour-hover--secondary-submit: $button-colour-hover--secondary;
$button-background-colour-hover--secondary-submit: $button-background-colour-hover--secondary;
$button-background-image-hover--secondary-submit: $button-background-image-hover--secondary;
$button-border-colour-hover--secondary-submit: $button-border-colour-hover--secondary;
$button-box-shadow-hover--secondary-submit: $button-box-shadow-hover--secondary;

$button-colour-active--secondary-submit: $button-colour-active--secondary;
$button-background-colour-active--secondary-submit: $button-background-colour-active--secondary;
$button-background-image-active--secondary-submit: $button-background-image-active--secondary;
$button-border-colour-active--secondary-submit: $button-border-colour-active--secondary;
$button-box-shadow-active--secondary-submit: $button-box-shadow-active--secondary;

@import "curtindesign/variables/button";
